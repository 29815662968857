<template>
  <div>
    <Navbar />
    <div class="app-body my-5 py-5">
      <div class="columns">
        <div class="column">
          <p class="text-subtitle bold">My Issuances</p>
          <b-table
            class="mt-5"
            :data="issuances"
            :loading="isLoading"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            paginated
          >
            <b-table-column
              field="title"
              label="Book Title"
              sortable
              v-slot="props"
            >
              {{ props.row.printedBook.title }}
            </b-table-column>
            <b-table-column
              field="authors"
              label="Authors"
              sortable
              v-slot="props"
            >
              {{ props.row.printedBook.authors.join(", ") }}
            </b-table-column>
            <b-table-column
              field="issueDate"
              label="Issued On"
              sortable
              v-slot="props"
            >
              {{ props.row.issueDate | date }}
            </b-table-column>
            <b-table-column
              field="dueDate"
              label="Due Date"
              sortable
              v-slot="props"
            >
              {{ props.row.dueDate | date }}
            </b-table-column>
            <b-table-column
              field="returnDate"
              label="Date Returned"
              sortable
              v-slot="props"
            >
              <span v-if="props.row.returnDate">
                {{ props.row.returnDate | date }}
              </span>
              <span v-else class="has-text-danger">
                To be Returned
              </span>
            </b-table-column>
            <b-table-column label="Overdue Days" sortable v-slot="props">
              <span v-if="!props.row.returnDate">
                {{ props.row.dueDate | daysDuration(new Date()) }} days
              </span>
              <span v-else>-</span>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/app/Navbar";
import Footer from "@/components/app/Footer";
import api from "@/services/dataService";

export default {
  name: "MyIssuances",
  components: {
    Navbar,
    Footer
  },
  data() {
    return {
      issuances: undefined,
      isLoading: false
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },
  async created() {
    await this.fetchIssuances();
  },
  methods: {
    async fetchIssuances() {
      this.isLoading = true;
      await api.get("/issuance/history/" + this.currentUser.id).then(res => {
        this.issuances = res.data;
      });
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
